.contactUs{
    margin: 10% 0 0 0;
    background: url("../images/bg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 280px;
    position:  relative;
}
.contactUs::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(21, 21, 21, 0.3));
}
.pos{
    position: relative;
}
.pos div{
    position: relative;
    top: -38%;
     -webkit-animation: updown 3s;
    animation: updown 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-bottom: 2px solid white;
}
@-webkit-keyframes updown {
    0%{
        top: -38%;
    }
    50%{
        top: -33%;
    }
    100%{
        top: -38%;
    }
}
@keyframes updown{
    0%{
        top: -38%;
    }
    50%{
        top: -33%;
    }
    100%{
        top: -38%;
    }
}
@media only screen and (max-width: 998px){
    .contactUs{
        margin: 10% 0 0 0;
        height: auto;
    }
    .pos div{
        position: relative;
    }
    .lst{
        margin-top: -25%;
    }
}