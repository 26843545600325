.loginRegister{
    width: 30%;
    position: fixed;
    top: 50%;
    overflow: auto;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loginRegister a{
    text-decoration: none;
}
.loginRegister img{
    display: block;
    width: 50%;
}
@media only screen and (max-width: 998px){
    .loginRegister{
        width: 80%;
    }
}
