.giveHeader{
    height: 30vh;
    display: none;
}
.giveHeader::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url("/public/images/bg.jpeg");
    background-position: top;
}
.giveHeader::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(to bottom, transparent, rgba(18, 17, 40, 1));
}
.giveHeader h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    text-align: center;
    color: white;
}
.sided{
    background: url("/public/images/equity.avif");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 520px;
}
.inside{
    margin-left: -19%;
    z-index: 2;
    position: relative;
}
.partnerships p{
    width: 90%;
    margin: -20px auto 0 auto;
    background: white;
    position: relative;
    z-index: 1;
    font-size: 0.875rem;
}
.pdiv{
    position: relative;
}
.become{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0%;
    z-index: 1;
    opacity: 0;
    transition: 1s;
}
.pdiv:hover > .become{
    top: 20%;
    opacity: 1;
}
@media only screen and (max-width: 998px){
    .dvs{
        display: none;
    }
    .inside{
        margin-left: 0%;
        z-index: 2;
        position: relative;
    }
}