.schoolImage{
    height: 45vh;
    object-fit: cover;
    object-position: center;
}
.welcome{
    position: relative;
    top: -50px;
    background: white;
}
.welcome p{
    width: 75%;
}
.bgs{
    background: white;
}
.director img{
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: top;
}
.programs div{
    flex: 22%;
    margin: 1%;
}
.programs hr{
    color: rgba(0,0,0,0.2);
}
@media only screen and (max-width: 998px){
    .schoolImage{
        height: auto;
    }
    .welcome p{
        width: 90%;
        text-align: justify;
    }
    .programs{
        padding: 0 5%;
    }
}