/* user profile section */
.userProfile{
    padding: 10px 5%;
    width: 100%;
}
.userProfile label{
    display: block;
    color: rgb(42, 40, 40);
    font-size: 0.9rem;
}
.userProfile input,
.userProfile textarea{
    margin: 5px 2px 2px 0;
    padding: 5px;
    border: none;
    border: 1px solid #dddddd;
    width: 100%;
}
.userProfile input[type='radio']{
    width: auto;
}
.userProfile button{
    background: darkcyan;
    color: white;
    padding: 6px;
    border: none;
    border-radius: 5px;
}
.userProfile label{
    font-weight: 300;
    font-size: 1.2rem;
    color: teal;
}
.userProfile input:focus,
.userProfile textarea:focus{
    outline: none;
}
.forgotPassword{
    width: 30%;
    margin: auto;
    position:fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    border: 1px solid rgb(220, 219, 219);
    padding: 20px;
    border-radius: 10px;
}
@media only screen and (max-width: 998px){
    .forgotPassword{
        width: 80%;
    }
}