#card{
    border-bottom: 4px solid red;
}
#card img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: top;
}
#card p{
    min-height: 100px;
}
.thumb img{
    min-height: 50px;
    max-height: 50px;
}
@media only screen and (max-width: 998px){
    #card img{
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: top;
    }
}