.sermons{
    position: relative;
}
.playpause-track,
.prev-track,
.next-track{
  padding: 10px;
  opacity: 0.8;
  transition: opacity .2s;
}
.fa-volume-low,
.fa-volume-high{
    opacity: 0.8;
    transition: opacity .2s;
}
.fa-volume-low:hover,
.fa-volume-high:hover{
    opacity: 1;
    cursor: pointer;
}
 
/* Change the opacity when mouse is hovered */
.playpause-track:hover,
.prev-track:hover,
.next-track:hover {
  opacity: 1.0;
  cursor: pointer;
}
.news p {
    font-size: 0.9rem;
}

.posts li {
    list-style: none;
    padding: 8px 10px;
    font-size: 0.9rem;
}

.posts li a {
    color: rgb(39, 38, 38);
}

.posts li:nth-child(even) {
    background: rgb(188, 188, 188);
}

.posts li:nth-child(odd) {
    background: rgb(232, 230, 230);
}

.posts ul {
    padding: 0;
    margin: 0;
}
.activated{
    border: 1px solid rgb(233, 232, 232);
    border-radius: 10px;
    padding: 10px;
}
input[type="range"] {
    --range-progress: 0;
    
    /* ... */
}
  
input[type="range"]::before {
    /* ... */
    background: #f50;
    width: var(--range-progress);
    /* ... */
}
input[type="range"] {
    --range-progress: 0;
    background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #fff 50%, #fff 100%);
    border: solid 1px #82CFD0;
    border-radius: 8px;
    height: 5px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    /* ... */
}
.controls{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 2px 0;
}
.controls button{
    background-color: teal;
    color: white;
    padding: 5px 10px;
    flex-flow: 1;
    display: inline-block;
}
.volume button{
    background-color: teal;
    color: white;
    padding: 5px 10px;
    flex-flow: 1;
    display: block;
}
.progress{
    margin: 10px;
    background: none;
}
.audio-image img{
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
.playList{
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
}
