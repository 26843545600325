@font-face {
    font-family: Damion;
    src: url("../Damion/Damion-Regular.ttf");
    background-size: cover;
}
.p_styled{
    color: #8d9297;
    font-family: "Damion", Sans-serif;
    font-size: 25px;
    font-weight: 400;
    text-decoration: none;
    line-height: 34px;
    text-shadow: 0px 0px 0px rgba(12,12,12,0.3);
}
.link{
    color: black;
}
.link:hover{
    color: red;
}
.midweek img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.cornetMessage{
    background: rgba(249, 241, 230, 0.5);
    padding: 24px 0;
    margin-bottom: 20px;
    text-align: center;
}
@media only screen and (max-width: 998px){
    .p_styled{
        font-size: 1rem;
    }
    .midweek img{
        height: auto;
        width: 100%;
    }
}