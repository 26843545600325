.filter_notifications{
    display: flex;
    justify-content: space-between;
    padding: 10px 5%;
    align-items: flex-end;
}
.filter_notifications input{
    margin-right: 5px;
    padding: 5px;
    border: none;
    border: 1px solid #dddddd;
}
.filter_notifications button:last-child{
    background: darkcyan;
    color: white;
    padding: 6px;
    border: none;
    border-radius: 5px;
}
.requests p{
    cursor: pointer;
}
.requests div{
    padding: 10px;
    border-radius: 5px;
}
.requests div:nth-child(odd){
    background: rgb(252, 246, 246);
}