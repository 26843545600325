.body-questions{
    background: rgb(250, 250, 250);
}
#floatingTextarea{
    height: 120px;
}
.questions img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.questions figcaption{
    font-size: 0.875rem;
}
.questions .date{
    font-size: 0.775rem;
}
.answeredby{
    text-align: right;
    font-size: 0.75rem;
}
.questions{
    padding: 10px;
    border-radius: 5px 5px 0 0;
}
.questions:nth-child(even){
    background: #dddd;
    border-bottom: 2px solid pink;
}
.qa{
    font-size: 1rem;
}