.staff img {
    border-radius: 100%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: top;
    /*background: rgba(3, 73, 134, 0.5);*/
}
@media only screen and (max-width: 998px){
    .staff img {
        border-radius: 10px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: top;
        background: transparent;
    }
}