.footer{
    padding: 5% 0;
    background: url("../images/bg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
}
.footer::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, transparent, rgba(21, 21, 21, 0.9));
}
.footer div{
    position: relative;
    z-index: 1;
    color: white;
}
.footer .lead{
    color: whitesmoke;
}
.footer a{
    text-decoration: none;
    color: whitesmoke;
    padding: 5px;
}
.footer a:last-child{
    border-bottom: none;
}
.footer img{
    width: 100%;
}
@media only screen and (max-width: 998px){
    .footer img{
        width: 50%;
        margin-bottom: 10px;
    }
}