.title h5{
    font-size: 2.2rem;
    font-weight: 900;
    text-shadow: 2px 2px #034986;
}
.title p{
    font-size: 1.2rem;
    font-weight: 700;
    text-shadow: 2px 2px #034986;
}
.carousel-item{
    position: relative;
}
.carousel-item::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.6));
}
.carousel-inner{
    height: 350px;
    background: linear-gradient(to bottom, #034986, transparent, white);
}
.carousel img{
    height: 350px;
    object-fit: cover;
    object-position: center;
}
.carousel-item h4{
    font-size: 5rem;
    padding-top: 20px;
    font-weight: 900;
    color: white;
    text-shadow: 2px 2px #034986;
    position: absolute;
    top: 10%;
    left: 5%;

}
@media only screen and (max-width: 998px){
    .carousel-item h4{
        font-size: 3rem;
    }
    .title h5{
        font-size: 1.4rem;
        font-weight: 900;
        text-shadow: 2px 2px #034986;
    }
    .title p{
        font-size: 0.9rem;
        font-weight: 700;
        text-shadow: 2px 2px #034986;
    }
}