#card3{
    min-height: 367px;
}
#card3 img{
    object-fit: contain;
    object-position: left;
    height: auto;
    width: 100%;
}
@media only screen and (max-width: 998px){
    #card3{
        min-height: 200px;
    }
    #card3 img{
        background: rgba(3, 73, 134, 0.5);
        min-height: 200px;
        object-fit: cover;
    }
}