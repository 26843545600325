.events{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.events div{
    flex: 45%;
    flex-grow: 0;
}
.events a{
    display: inline;
}
.events img{
    width: 100%;
    height: 200px;
    border-radius: 5px;
}
@media only screen and (max-width: 998px){
    .events div{
        flex: 100%;
    }
    .events a{
        color: darkcyan;
    }
    .events img{
        width: 100%;
        height: auto;
    }
}