.Dashboard{
    display: flex;
}
.Dashboard .div:first-child{
    flex: 15%;
    background: darkcyan;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 30px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}
.Dashboard .div:last-child{
    flex: 85%;
    flex-grow: 1;
    background: linear-gradient(to bottom, transparent, rgb(210, 209, 209));
}
.Dashboard .div:first-child img{
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin: 0 auto 0 auto;
    border-radius: 100%;
    border: 2px solid rgb(255, 255, 255);
}
.Dashboard .div:first-child figcaption{
    text-align: center;
    color: white;
    padding: 10px 0 5px 0;
}
.Dashboard .div:first-child small{
    display: block;
    text-align: center;
    color: white;
}
.Dashboard .div:first-child hr{
    background-color: rgb(185, 185, 185);
    border: 0;
    height: 1px;
    margin: 10px 0 0 0;
}
.Dashboard .div:first-child a{
    display: block;
    padding: 10px;
    text-decoration: none;
    color: white;
    transition: 1s;
}
.Dashboard .div:first-child a:hover{
    background: green;
    border-bottom: 2px solid white;
}
.actives{
    background: green;
    border-bottom: 2px solid white;
}
.Dashboard .div a:last-child{
    background: rgba(3, 109, 104, 0.5);
}
.Dashboard .div a:last-child:hover{
    background: rgb(111, 1, 1);
    color: white;
}
.Dashboard .div:last-child .system_name{
    padding: 20px;
    text-align: center;
    background: rgb(218, 217, 217);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}
.Dashboard .div:last-child .system_name p{
    font-size: 2.2rem;
    font-weight: 900;
    color: tomato;
}
.quickStats{
    display: flex;
    gap: 2%;
    text-align: center;
    padding: 30px 5%;
}
.quickStats div{
    flex: 32%;
    background: rgb(13, 96, 123);
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: all 1s;
}
.quickStats div:hover{
    background: rgb(8, 62, 79);
}
.quickStats div:nth-child(2){
    background: teal;
}
.quickStats div:nth-child(3){
    background: tomato;
}
.quickStats div:nth-child(2):hover{
    background: rgb(1, 86, 86);
}
.quickStats div:nth-child(3):hover{
    background: rgb(194, 70, 48);
}
.quickStats p{
    padding: 1px 0;
    font-size: 1.2rem;
}
.quickStats span{
    color: white;
    font-size: 1.5rem;
    font-weight: 900;
}
table{
    width: 100%;
    border-collapse: collapse;
}
thead{
    background: teal;
    color: white;
}
th, 
td{
    text-align: left;
    border: 1px solid #dddddd;
    padding: 10px;
}
td:last-child:hover{
    background: rgba(255, 0, 0, 0.5);
    color: white;
    cursor: pointer;
}
tr:nth-child(even){
    background: #dddddd;
}
.questions{
    padding: 0 5%;
}

.filter{
    display: flex;
    justify-content: space-between;
    padding: 10px 5%;
    align-items: flex-end;
}
.filter form:first-child{
    flex: 50%;
}
.filter input{
    margin-right: 5px;
    padding: 5px;
    border: none;
    border: 1px solid #dddddd;
}
.filter button{
    background: darkcyan;
    color: white;
    padding: 6px;
    border: none;
    border-radius: 5px;
}
.filter form:last-child{
    flex: 50%;
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: flex-end;
}
.filter label{
    color: grey;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 2;
    display: none;
}
.inner{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    padding: 2% 3%;
    background: #0d6c79;
    color: white;
    border-bottom: 2px solid white;
}
.inner2{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40%;
    max-height: 75vh;
    overflow-y: auto;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    padding: 2% 3%;
    background: linear-gradient(to bottom, white, #0d6c79,#0d3968);
    border-bottom: 2px solid white;
}
.inner h2{
    color: tomato;
    text-shadow: 1px 1px white;
    font-family: Playfair;
}
.inner2 h2{
    color: green;
    text-shadow: 1px 1px white;
    font-family: Playfair;
}
.inner h4{
    font-weight: 900;
}