.forms{
    padding: 2.5% 2.5%;
    border: 1px solid rgb(222, 220, 220);
    border-radius: 10px;
    background: whitesmoke;
}
.logos{
    width: 30%;
}
#h1{
    font-size: 1.4rem;
    padding-top: 15px;
}
@media only screen and (max-width: 998px){
    .logos{
        width: 50%;
    }
    #h1{
        font-size: 1.2rem;
    }
    #h1 span{
        font-size: 1.3rem;
    }
}