.socials a{
    padding: 10px;
    border-right: 1px solid rgba(245, 245, 245, 0.1);
    font-size: 15px;
}
#fa-location-dot{
    border: none;
    padding: 10px;
    font-size: 20px;
}
.top{
    border-bottom: 1px solid rgba(245, 245, 245, 0.1);
    padding: 0 5%;
}
@media only screen and (max-width: 998px){
    .socials a{
        padding: 2.5px;
        border-right: 1px solid rgba(245, 245, 245, 0.1);
        font-size: 15px;
    }
    .top{
        display: flex;
        padding: 10px 0;
    }
    .top div:first-child{
        align-items: center;
        justify-content: center;
    }
    #fa-location-dot{
        border: none;
        padding: 2.5px;
        font-size: 15px;
    }
    .top span{
        font-size: 0.875rem;
        padding: 0 1%;
    }
}