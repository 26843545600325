.headed{
  color: #252525;
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}
:root {
  --header: #0d3968;
  --blue: #0d3968;
  --hover: #092a4f;
}
.bg{
  background: var(--blue);
}
.bg:hover{
  background: var(--hover);
}
.fg{
  color: var(--blue);
} 
@media only screen and (max-width: 998px){
  .headed{
    font-size: 1.2rem;
  }
}