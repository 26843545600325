.event img{
    width: 100%;
    /*height: 300px;*/
    object-fit: cover;
    object-position: center;
}
.eventGallery{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 10px;
}
.eventGallery img{
    width: 31%;
    flex-grow: 1;
    border-radius: 5px;
}
.flex_other_details{
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}
.flex_other_details img{
    width: 40%;
    height: 100px;
    object-fit: cover;
    border-radius: 2px;
}
.flex_other_details div{
    width: 58%;
}
.flex_other_details h2{
    font-size: 1rem;
}
.flex_other_details p{
    font-size: 0.875rem;
}
.flex_other_details a{
    color: rgb(108, 107, 107);
    transition: 1s;
}
.flex_other_details a:hover{
    color: rgb(23, 115, 15);
}